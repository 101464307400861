<template>
  <div class="modal fade modal-authorization__bg" id="authorization" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="authorization" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-authorization" role="document">
      <div class="modal-content container-shadow">
        <div class="modal-header">
          <div class="title-with-icon">
            <div class="title-with-icon__icon title-with-icon__icon_login"></div>
            <div class="title-with-icon__title">
              <span rel="log-in" class="title-login active">
                Войти в личный кабинет
              </span>
              <span rel="restore-pass" class="title-pass">Восстановление пароля</span>
              <span rel="registration" class="title-register">
                {{ head[type] }}
              </span>
              <span rel="letter" class="title-letter">Вам письмо!</span>
            </div>
          </div>
          <button type="button" class="close modal-close hidden" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <template v-if="$route.path === '/sign-in'">
            <login-form />
            <restore-pass-form/>
          </template>
          <template v-if="$route.path === '/sign-up'">
            <registration-form/>
          </template>
          <success-letter :email="email" :title="title" />
        </div>
        <div class="modal-footer p-1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import {eventBus} from "@/main";
import LoginForm from "@/components/LoginForm.vue";
import ApiService from "../../services/api.service";
import {TokenService} from "../../services/token.service";
import RestorePassForm from "@/components/RestorePassForm.vue";
import RegistrationForm from "@/components/register/RegistrationForm.vue";
import SuccessLetter from "@/components/SuccessLetter.vue";
import regTypeMixin from "@/mixins/regTypeMixin";
export default {
  name: "SignInUp",
  components: {SuccessLetter, RegistrationForm, RestorePassForm, LoginForm},
  data() {
    return {
      loading: false,
      question: null,
      email: null,
      title: null,
      user: JSON.parse(TokenService.getUser()),
      head: {
        user: 'Регистрация',
        regteam: 'Регистрация аккаунта региональной управленческой команды',
        company: 'Регистрация корпоративного аккаунта',
      }
    }
  },
  mixins: [regTypeMixin],
  mounted() {
    this.$parent.init();
    setTimeout(() => {
      this.init()
    }, 500)

    eventBus.$on('show:letter', (data) => {
      this.email = data.email
      this.title = data.title
      let modalForm = $('.modal-form');
      let modalAuthorization = $('.modal-authorization');
      let modalTitle = $('.modal-header .title-with-icon__title span');
      let modalAuthorizationWindow = $('#authorization');

      if (!$('html').hasClass('fp-viewing-footer')) {
        $('html').addClass('fp-viewing-footer');
      }

      if (!$('html').hasClass('authorization-bg')) {
        $('html').addClass('authorization-bg');
      }

      let rel = 'letter';
      modalAuthorizationWindow.modal('show')
      modalForm.removeClass('active');
      modalTitle.removeClass('active');
      modalAuthorization.removeClass('log-in restore-pass registration letter');
      $('.modal-form[rel=' + rel + ']').addClass('active');
      modalAuthorization.addClass(rel);
      $('.modal-header .title-with-icon__title span[rel=' + rel + ']').addClass('active');
      $('.modal-register-conditions-sms').addClass('active');
    })
  },
  methods: {
    init() {
      if(this.user) {
        this.email = this.user.email
      }
      let self = this
      $(function() {
        let modalAuthorization = $('#authorization');
        let modalForm = $('.modal-form');
        let modalTitle = $('.modal-header .title-with-icon__title span');

        $('html').addClass('fp-viewing-footer');
        if (!$('html').hasClass('authorization-bg')) {
          $('html').addClass('authorization-bg');
        }

        let navForm = $('.nav-form');
        navForm.click(function () {
          let rel = $(this).attr('rel');
          modalForm.removeClass('active');
          modalTitle.removeClass('active');
          modalAuthorization.removeClass('log-in restore-pass registration letter');
          $('.modal-form[rel=' + rel + ']').addClass('active');
          modalAuthorization.addClass(rel);
          $('.modal-header .title-with-icon__title span[rel=' + rel + ']').addClass('active');
        });

        if(self.$route.path === '/sign-in') {
          let rel = 'log-in';
          modalAuthorization.modal('show')
          modalForm.removeClass('active');
          modalTitle.removeClass('active');
          modalAuthorization.removeClass('log-in restore-pass registration letter');
          $('.modal-form[rel=' + rel + ']').addClass('active');
          modalAuthorization.addClass(rel);
          $('.modal-header .title-with-icon__title span[rel=' + rel + ']').addClass('active');
          $('.modal-register-conditions-sms').addClass('active');
        }

        if(self.$route.path === '/sign-up') {
          $('#authorization').modal('show')
          let modalForm = $('.modal-form');
          let modalAuthorization = $('.modal-authorization');
          let modalTitle = $('.modal-header .title-with-icon__title span');
          modalForm.removeClass('active');
          modalTitle.removeClass('active');
          modalAuthorization.removeClass('log-in restore-pass registration letter');
          $('.modal-form[rel=registration]').addClass('active');
          modalAuthorization.addClass('registration');
          $('.modal-header .title-with-icon__title span[rel=registration]').addClass('active');
        }

        $('#enter').click(function () {
          $('#authorization').modal('show')
        })

        $('#authorization').on('hide.bs.modal', function () {
          $('html').removeClass('authorization-bg');
          $('html').removeClass('fp-viewing-footer');
          modalForm.removeClass('active');
          modalTitle.removeClass('active');
          modalAuthorization.removeClass('log-in restore-pass registration letter');
          $('.modal-form[rel="log-in"]').addClass('active');
          $('.modal-header .title-with-icon__title span[rel="log-in"]').addClass('active');
        });

        eventBus.$on('user:upd', () => {
          $('.modal-close').trigger('click');
        })

      })
    },
    submit() {
      this.loading = true
      ApiService.post('questions', {
        question: this.question,
        email: this.email
      }).then(() => {
        this.question = null
        this.email = null
        $('.modal-close').trigger('click');
        this.loading = false
      }).catch(() => {
        this.question = null
        this.email = null
        $('.modal-close').trigger('click');
        this.loading = false
      })
    }
  },
  watch: {
    '$route.path': {
      handler: function(val, oldVal) {
        if(val && oldVal) {
          $(function() {
            let modalForm = $('.modal-form');
            let modalAuthorization = $('.modal-authorization');
            let modalTitle = $('.modal-header .title-with-icon__title span');
            let modalAuthorizationWindow = $('#authorization');

            if (!$('html').hasClass('fp-viewing-footer')) {
              $('html').addClass('fp-viewing-footer');
            }

            if (!$('html').hasClass('authorization-bg')) {
              $('html').addClass('authorization-bg');
            }

            if(val === '/sign-in') {
              let rel = 'log-in';
              modalAuthorizationWindow.modal('show')
              modalForm.removeClass('active');
              modalTitle.removeClass('active');
              modalAuthorization.removeClass('log-in restore-pass registration letter');
              $('.modal-form[rel=' + rel + ']').addClass('active');
              modalAuthorization.addClass(rel);
              $('.modal-header .title-with-icon__title span[rel=' + rel + ']').addClass('active');
              $('.modal-register-conditions-sms').addClass('active');
            }

            if(val === '/sign-up') {
              modalAuthorizationWindow.modal('show')
              modalForm.removeClass('active');
              modalTitle.removeClass('active');
              modalAuthorization.removeClass('log-in restore-pass registration letter');
              $('.modal-form[rel=registration]').addClass('active');
              modalAuthorization.addClass('registration');
              $('.modal-header .title-with-icon__title span[rel=registration]').addClass('active');
            }
          })
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.hidden {
  visibility: hidden;
}
.authorization-bg .modal {
  @media only screen and (min-device-width: 1024px) {
    top: 18rem;
    .modal-authorization {
      align-items: start;
    }
  }
  @media (max-width: 767px) {
    top: 54px;
    .modal-authorization {
      align-items: start;
      margin-bottom: 80px;
    }
  }
}
</style>